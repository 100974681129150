import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '@/store'
import router from '@/router'
import { User } from '@/models/user'
import { App, inject } from 'vue'
import { CancelationCode, OrderEvaluation, OrderReservation } from '@/models/order'
import * as Sentry from '@sentry/vue'

function authConfig(): AxiosRequestConfig {
  return {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('gw_user_token')}` },
  }
}

export const API = {
  auth: {
    forgot: (data: { email: string }) => axios.post('auth/forgot', data, authConfig()),
    info: {
      get: () => axios.get('auth/info', authConfig()),
      update: (data: Partial<User>) => axios.post('auth/info', data, authConfig()),
    },
    invalidate: () => axios.post('auth/invalidate', null, authConfig()),
    login: (data: { login: string; password: string }) => axios.post('auth/login', data),
    refresh: () => axios.post('auth/refresh', null, authConfig()),
    reset: (data: { code: string; email: string; password: string; password_confirmation: string }) =>
      axios.post('auth/reset', data, authConfig()),
    signup: (data: {
      email: string;
      name: string;
      password: string;
      password_confirmation: string;
      phone_number: string;
      surname: string;
    }) => axios.post('auth/signup', data),
  },
  barbers: (storeId: number, serviceId: number) => axios.get(`stores/${storeId}/services/${serviceId}/barbers`),
  categories: (storeId: number) => axios.get(`stores/${storeId}/categories`),
  category: (storeId: number, categoryId: number) => axios.get(`stores/${storeId}/categories/${categoryId}`),
  orders: {
    cancelationCodes: () => axios.get<{ data: CancelationCode[] }>('/orders/cancelation-codes'),
    create: (order: {
      barber_id: number;
      customer: {
        create_account: boolean;
        email: string;
        marketing_agreement: boolean;
        name: string;
        phone_number: string;
        surname: string;
        tos_agreement: boolean;
      };
      date: string;
      note: string;
      service_id: number;
      store_id: number;
      time: string;
      utm: Record<string, string>
    }) => axios.post<{ data: OrderReservation }>('orders', order),
    delete: (orderId: number, code: string, reason?: string, reason_text?: string) => 
      axios.delete<{ data: OrderReservation }>(
        `orders/${orderId}/${code}`,
        {
          data: {
            order_cancelation_reason: reason,
            order_cancelation_reason_text: reason_text
          }
        }
      ),
    evaluation: {
      get: (orderId: string, code: string) => axios.get(`/orders/${orderId}/evaluations/${code}`),
      post: (orderId: string, code: string, data: OrderEvaluation) => axios.post(`/orders/${orderId}/evaluations/${code}`, data)
    }
  },
  services: (storeId: number) => axios.get(`stores/${storeId}/services`),
  session: (data?: { data: any }) => axios.post('session', data),
  store: (id: number) => axios.get(`stores/${id}`),
  stores: () => axios.get('stores'),
  survey: {
    get: () => axios.get('surveys/survey/branch-visit-evaluation'),
    post: (data: any) => axios.post('surveys/survey/branch-visit-evaluation', data),
  },
  times: (storeId: number, serviceId: number, barberId: number, date: string) =>
    axios.get(`stores/${storeId}/services/${serviceId}/barbers/${barberId}/times/${date}`),
  user: {
    orders: {
      create: (order: {
        barber_id: number;
        customer: {
          create_account: boolean;
          email: string;
          marketing_agreement: boolean;
          name: string;
          phone_number: string;
          surname: string;
          tos_agreement: boolean;
        };
        date: string;
        note: string;
        service_id: number;
        store_id: number;
        time: string;
        utm?: Record<string, string>
      }) => axios.post<{ data: OrderReservation }>('user/orders', order, authConfig()),
      delete: (id: number, reason?: string, reason_text?: string) => 
        axios.delete<{ data: OrderReservation }>(
          `user/orders/${id}`, 
          { 
            data: {
              order_cancelation_reason: reason,
              order_cancelation_reason_text: reason_text
            },
            ...authConfig() 
          }
        ),
      detail: (id: number) => axios.get(`user/orders/${id}`, authConfig()),
      list: () => axios.get('user/orders', authConfig()),
    },
  },

  // eslint-disable-next-line vue/sort-keys
  mocks: [
    // 'account/login',
    // 'account/user',
    // 'password-reset',
  ],
  version: 'v1',
}

export type Api = typeof API

const ApiPlugin = {
  ...API,
  install: async (app: App) => {
    let refreshRequest: Promise<AxiosResponse> | undefined

    axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_VERSION || API.version}`
    // axios.defaults.baseURL = 'capacitor://localhost'

    axios.interceptors.request.use(
      function (request: any) {
        if (API.mocks.find((mock) => request?.url.includes(mock))) {
          request.method = 'GET'
          const requestUrl = request.url?.endsWith('/') ? request.url.slice(0, -1) : request.url
          request.url = `${window.location.origin}/mocks/${requestUrl || ''}.json`
        }

        request.headers['Localization'] = app.config.globalProperties.$i18n?.locale || localStorage.getItem('gw_locale') || 'sk'
        return request
      },
      function (error: any) {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      function (response: AxiosResponse) {
        return response
      },
      async function (error: AxiosError) {
        if (error.response?.status === 401 && store.getters.isLoggedIn) {
          try {
            if (!refreshRequest) {
              refreshRequest = API.auth.refresh()
            }
            const {
              data: {
                response: { token },
              },
            } = await refreshRequest
            error.config.headers = { ...error.config.headers, Authorization: `Bearer ${token}` }
            return axios(error.config).then((data) => {
              store.commit('updatedToken', token)
              return data
            })
          } catch (err) {
            store.dispatch('logout', false)
            router.push('/')
          } finally {
            refreshRequest = undefined
          }
        } else if (
          (error.response && error.response.status >= 500 && error.response?.status < 600) ||
          typeof error.response === 'undefined'
        ) {
          Sentry.captureException(error)
          document.dispatchEvent(new CustomEvent('gwServerError', { detail: null }))
        }
        return Promise.reject(error)
      }
    )
    app.provide('[gw-api]', API)
  },
}

export function useApi() {
  return inject('[gw-api]') as Api
}

export default ApiPlugin
