import { User } from '@/models/user'
import { RatingTag } from '@/models/rating'

export interface Barber {
  avatar: { path: string };
  first_name: string;
  id: number;
  languages?: string | null;
  last_name?: string | null;
  practice_since: number;
}

export interface PricePolicy {
  end_date: string | null
  price: number
  start_date: string
}

export interface Service {
  description: string;
  duration_from: number;
  duration_to: number;
  id: number;
  name: string;
  price_policy?: PricePolicy[];
}

export interface Category {
  description: string;
  id: number;
  image: { path: string } | null;
  name: string;
  services: Service[];
}

export interface Address {
  city: string;
  street: string;
  zip: string;
}

export interface Store {
  address: string;
  city: string;
  email: string;
  id: number;
  image: { path: string } | null;
  name: string;
  phone: string;
  phone_from_time: string | null;
  phone_until_time?: string | null;
  zip: string;
}

export class Order {
  id?: number
  duration?: number
  barber?: Barber
  note: string
  service?: Service
  service_price?: number
  store?: Store
  date?: Date
  time: string
  reservation_datetime?: string
  status?: OrderStatus
  order_status?: string
  order_status_label?: string

  constructor(barber?: Barber, note?: string, service?: Service, shop?: Store, date?: Date, time?: string) {
    this.barber = barber
    this.note = note || ''
    this.service = service
    this.store = shop
    this.date = date
    this.time = time || ''
  }
}

export interface OrderEvaluation {
  id?: number;
  rating_1: number;
  rating_1_comment: string;
  rating_2: number;
  rating_2_comment: string;
  rating_tags: { field: string; tag: RatingTag }[]
}

export interface OrderReservation extends OrderEvaluation {
  barber: Barber;
  categories: Category[];
  duration?: number;
  evaluation_code: string
  id: number;
  is_evaluated: boolean
  note: string;
  order_status: string;
  order_status_label: string;
  reservation_datetime: string;
  service: Service;
  service_price: number;
  store: Store;
  user?: User;
}

export interface EvaluationOrderReservation {
  barber: {
    avatar: Barber['avatar'],
    first_name: Barber['first_name']
  };
  evaluation_code: string
  id: number;
  service: {
    name: Service['name']
  };
  store: {
    city: Store['city'];
    image: Store['image'];
    name: Store['name'];
  }
}

export enum OrderStatus {
  ACTIVE = 'active',
  CANCELLED = 'canceled',
  COMPLETED = 'finished'
}

export type OrderSuccessFlowState = 'success' | 'add_to_calendar' | 'create_account' | 'set_password' | 'transition'

export interface CancelationCode {
  code: string
  name: string
}
